












































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListItemAddress from '@/components/AppListItemAddress.vue';
import DELIVERIES from '@/graphql/queries/DeliveryLocations.graphql';
import DELIVERY_DELETE from '@/graphql/mutations/DeliveryLocationDelete.graphql';
import pluralNumber from '@/utils/pluralNumber';
import { DeliveryLocation } from '@/types/schema';

export default Vue.extend({
  name: 'AdminDairies',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
    AppListItem,
    AppListItemAddress,
  },
  data() {
    return {
      deliveries: [] as DeliveryLocation[],
    };
  },
  methods: {
    getSubtitle(delivery: DeliveryLocation) {
      const miles = pluralNumber(delivery.miles, 'Mile');
      return miles;
    },
    async deleteDelivery(id: DeliveryLocation['id']) {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: DELIVERY_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.deliveries.refetch();
        this.$notify({
          text: 'Dairy deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    deliveries: {
      query: DELIVERIES,
      fetchPolicy: 'cache-and-network',
    },
  },
});
